<template>
  <div class="drvers">
    <LogsList :type="`managers`">
      <PageTitle title="SIDE_MENU.LOGS" subtitle=""></PageTitle>
    </LogsList>
  </div>
</template>
<script>
import LogsList from "@/components/Modules/Logs/LogsList/index.vue"
import PageTitle from "@/components/Shared/PageTitle/index.vue"
export default {
  components: {
    LogsList,
    PageTitle
  }
}
</script>